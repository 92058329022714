import { Injectable } from '@angular/core';
import { environment } from '../../../environments/environment';
import { HttpHeaders } from '@angular/common/http';

@Injectable({
    providedIn: 'root'
})
export class ApiUrlService {
    constructor() {}

    endpoint = environment.apiUrl;
    token = localStorage.getItem('accessToken');
    headers = new HttpHeaders({
        'Content-type': 'application/json',
        'x-api-key': 'eyJhbGciOiJIUzI1NiJ9.c2VhdGVsLWFwaS1kZXY.NsFhP0C7pqeONbcGAf42lQLLe6AIgy1QdOUOrg0uqcI',
        Authorization: this.token
    });
}
